<template>
  <div class="p-1 p-md-4 m-md-4">
    <CRow>
      <CCol md="1"> </CCol>
      <CCol md="10">
        <CCard class="mx-md-4 mb-0">
          <CCardBody class="m-md-8">
            <h4 class="text-center pb-3">Credit Units</h4>
            <CForm label="Units" label-for="txtUnits" :label-cols="3">
              <CInput
                id="txtUnits"
                type="text"
                v-model="units"
                placeholder="Enter units to credit"
                autocomplete="units to credit"
              ></CInput>
            </CForm>
            <CForm label="User Name" label-for="txtUserName" :label-cols="3">
              <CInput
                id="txtUserName"
                type="text"
                v-model="userName"
                placeholder="Enter receiver's username"
                autocomplete="receiver's username"
              ></CInput>
            </CForm>

            <CForm label="Amount" label-for="amount" :label-cols="3">
              <CInput
                id="amount"
                type="text"
                v-model="amount"
                placeholder="Enter the amount paid"
              ></CInput>
            </CForm>

            <CSelect
              horizontal
              :options="mappedPaymentModes()"
              placeholder="Select Payment Method "
              :value.sync="selectedPaymentModeValue"
            />

            <CSelect
              horizontal
              :options="mappedCurrencies()"
              placeholder=" Select Currency "
              def
              :value.sync="selectedCurrencyValue"
            />

            <CForm label="Password" label-for="txtPassword" :label-cols="3">
              <CInput
                id="txtPassword"
                type="password"
                v-model="password"
                placeholder="Enter your password"
                autocomplete="your password"
              ></CInput>
            </CForm>
            <CButton
              block
              color="facebook"
              class="mb-2"
              type="submit"
              :disabled="loading"
              @click="handleUnitsTransfer"
              >Credit Units</CButton
            >
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import { mapGetters, mapActions } from 'vuex';
import {} from '@/services/notification.service';

export default {
  name: 'CreditUser',
  components: {
    vSelect,
  },
  data() {
    return {
      units: '',
      userName: '',
      password: '',
      selectedPaymentModeValue: '',
      selectedCurrencyValue: '',
      amount: '',
    };
  },
  notifications: {
    transferSuccess: {
      title: 'Success',
      message: 'Units were transferred successful!',
      type: 'success',
    },
    updateFailed: {
      title: 'Failed',
      message:
        'Update failed at this time, kindly check your network and try again!',
      type: 'error',
    },
    validationFailed: {
      title: 'Failed',
      message: 'All fields are required',
      type: 'error',
    },
    invalidUnits: {
      title: 'Failed',
      message:
        'Your units balance cannot be less than units you want to transfer.',
      type: 'error',
    },
    invalidPaymentMethod: {
      title: 'Failed',
      message: 'You did not select a payment mode',
      type: 'error',
    },
    invalidCurrency: {
      title: 'Failed',
      message: 'You did not select a currency',
      type: 'error',
    },
  },
  computed: {
    ...mapGetters('admin', [
      'paymentModes',
      'currencies',
      'loading',
      'serverResponse',
    ]),
  },

  created() {
    this.fetchPaymentModes();
    this.fetchCurrencies();
  },
  methods: {
    ...mapActions('admin', [
      'fetchPaymentModes',
      'fetchCurrencies',
      'creditUnits',
    ]),

    mappedPaymentModes() {
      return this.paymentModes.payment_modes.map((mode) => ({
        label: mode.name,
        value: mode.id,
      }));
    },
    mappedCurrencies() {
      return this.currencies.currencies.map((currency) => ({
        label: `${currency.name} ( ${currency.code} ) `,
        value: currency.id,
      }));
    },

    handleUnitsTransfer: async function () {
      let units = parseFloat(this.units);
      let amount = parseFloat(this.amount);

      if (
        this.userName.trim() == '' ||
        units <= 0 ||
        this.password.trim == '' ||
        this.amount < 1
      ) {
        this.validationFailed();

        return;
      }

      if (
        this.selectedPaymentModeValue == '' ||
        this.selectedPaymentModeValue == null
      )
        return this.invalidPaymentMethod();

      if (
        this.selectedCurrencyValue == '' ||
        this.selectedCurrencyValue == null
      )
        return this.invalidCurrency();

      let requestData = {
        amount: amount,
        units: units,
        receiver: this.userName.trim(),
        password: this.password.trim(),
        currency_id: this.selectedCurrencyValue,
        payment_mode_id: this.selectedPaymentModeValue,
      };

      let status = await this.creditUnits({ data: requestData });
      if (!status) {
        this.updateFailed({ message: this.serverResponse.message });
      } else {
        this.transferSuccess();
        this.units = '';
        this.userName = '';
        this.password = '';
        this.selectedValue = '';
      }
    },
  },
};
</script>
